<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="createType == 'edit'"
              >Edit Deal Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="createType == 'add'">Add Deal</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="createType == 'edit'">Edit Deal Information</span>
            <span v-if="createType == 'add'">Add Deal</span>
          </h3>

          <!-- <p class="py-4">
            This is where we are going to add the deal. Deals are a critical
            component in getting us those sweet sweet fees. Simply add their
            name, type, and areas they lend in and BOOM! Retirement.
          </p> -->
          <v-form
            @submit="submitCreateDeal"
            ref="form"
            v-model="formRules.valid"
          >

    
              <v-row v-if="createType == 'edit'">
                <v-col>
                  <v-select
                    label="Deal Status"
                    v-model="dealData.status"
                    :items="dealActiveOptions"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.clientFirstName"
                    label="First Name"
                    :rules="formRules.required"
                  ></v-text-field
                ></v-col>
                <v-col>
                  <v-text-field
                    v-model="dealData.clientLastName"
                    label="Last Name"
                    :rules="formRules.required"
                  ></v-text-field
                ></v-col>
              </v-row>
              <br />
              <br />

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.city"
                    label="City"
                    :rules="formRules.required"
                  ></v-text-field>
                  <v-select
                    :items="provinceOptions"
                    v-model="dealData.province"
                    label="Province"
                    :rules="formRules.required"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="dealData.locationNotes"
                    label="Location Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.propertyValue"
                    label="Property Value"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                  <p class="caption">
                    Add any interesting notes which may be useful. Type of
                    property, how many units, etc. Recently renovated?
                  </p>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="dealData.propertyValueNotes"
                    label="Property Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.propertyOwing"
                    label="Owing Against"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                  <p class="caption">1st? 2nd? LOC? Any payouts in this?</p>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="dealData.propertyOwingNotes"
                    label="Owing Against Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>
  
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.amountRequested"
                    label="Amount Requested"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                  <p class="caption">1st? 2nd? LOC? Any payouts in this?</p>
                           <v-text-field
                    v-model="dealData.mortgagePosition"
                    label="Mortgage Position"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="dealData.amountRequestNotes"
                    label="Amount Requested Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.ltv"
                    label="Loan To Value"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="dealData.gds"
                    label="Gross Debt Service Ratio (PITH)"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="dealData.tds"
                    label="Total Debt Service Ratio (PITH + Other Liabilities)"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="dealData.incomeNotes"
                    label="Income Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="dealData.liabilityNotes"
                    label="Liability Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

                     <v-row>
                <v-col>
                  <v-textarea
                    v-model="dealData.creditNotes"
                    label="Credit Notes"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="dealData.summaryNotes"
                    label="Summary"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="dealData.exitStrategy"
                    label="Exit Strategy"
                    type="number"
                    outlined
                    :rules="formRules.required"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dealData.proposedTerm"
                    label="Proposed Term (add word year, or month at end)"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="dealData.proposedRate"
                    label="Proposed Interest Rate (%)"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="dealData.proposedLenderFee"
                    label="Proposed Lender Fee (%)"
                    type="number"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
<v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="createType == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateDeal"
                  >Create Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  v-if="createType == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateDeal"
                  >Update Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'edit'"
                  @click="
                    $router.push({ path: `/dashboard/deal/read/${dealId}` })
                  "
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'add'"
                  @click="$router.push({ path: `/dashboard/deal/list` })"
                  >Go To Deal List</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "DealAdd",
  props: ["dealId"],
  data() {
    return {
      createType: "",
      dealActiveOptions: ["active", "deactivated", "awarded", "completed"],
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      dealData: {
        clientFirstName: "",
        clientLastName: "",
        city: "",
        province: "",
        locationNotes: "",
        mapLink: "",
        propertyValue: "",
        propertyOwing: "",
        amountRequested: "",
        propertyValueNotes: "",
        propertyOwingNotes: "",
        amountRequestNotes: "",
        ltv: "",
        gds: "",
        tds: "",
        introduction: "",
        incomeNotes: "",
        liabilityNotes: "",
        summaryNotes: "",
        exitStrategy: "",
        proposedRate: "",
        proposedLenderFee: "",
        proposedTerm: "",
        active: "",
      },
      provinceOptions: [
        { value: "bc", text: "British Columbia" },
        { value: "ab", text: "Alberta" },
        { value: "on", text: "Ontario" },
      ],
    };
  },
  methods: {
    submitCreateDeal() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/deal", this.dealData)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(
              `/dashboard/deal/read/${res.data.insertedDealId}`
            );
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
    submitUpdateDeal() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .patch(`/deal/${this.dealData.id}`, this.dealData)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(`/dashboard/deal/read/${this.dealData.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
  },
  mounted() {
    if (this.$route.name == "dealAdd") {
      this.createType = "add";
      this.componentLoading = false;
    } else {
      this.createType = "edit";
      API()
        .get(`/deal/read/${this.dealId}`)
        .then((res) => {
          this.dealData = res.data.recordset[0];
          this.componentLoading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.dispatch("Auth/silentLogin");
            }
          }
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>